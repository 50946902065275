<template>
  <div class="seedDB ml-5">
    <v-form ref="form" @submit.prevent="handleSubmit">
      <v-btn
        id="seedDB"
        class="mt-5"
        :loading="loading4"
        :disabled="loading4"
        color="success"
        type="submit"
        @click="loader = 'loading4'">
        Seed DB
        <span slot="loader" class="custom-loader">
          <v-icon light> mdi-cached </v-icon>
        </span>
      </v-btn>
    </v-form>

    <div class="mt-5">
      <div>Colors:</div>
      <div>
        <v-btn class="primary px-1 mx-2"> primary </v-btn>
        <v-btn class="secondary px-1 mx-2"> secondary </v-btn>
        <v-btn class="accent px-1 mx-2"> accent </v-btn>
        <v-btn class="error px-1 mx-2"> error </v-btn>
        <v-btn class="info px-1 mx-2"> info </v-btn>
        <v-btn class="success px-1 mx-2"> success </v-btn>
        <v-btn class="warning px-1 mx-2"> warning </v-btn>
        <v-btn class="ma-2" tile> Tile </v-btn>
        <v-btn class="ma-2"> Nothing </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
  // @ is an alias to /src
  import axios from 'axios';

  export default {
    name: 'ClientEdit',
    data() {
      return {
        loader: false,
        loading4: false,
      };
    },
    created() {
      //do something after creating vue instance
      // declare a request interceptor
      axios.interceptors.response.use(
        //   config => {
        //   // perform a task before the request is sent
        //   console.log( 'Request was sent: ', config );
        //
        //   return config;
        // }
        (response) => {
          // if ( response.data ) {
          //   console.log( response.data );
          // }

          return response;
        },
        (err) => {
          if (err.response) {
            // client received an error response (5xx, 4xx)
            console.log(err.response.data.message);
          } else if (err.request) {
            // client never received a response, or request never left
            console.log(err.request);
          } else {
            console.log(err);
          }
        }
      );
    },
    methods: {
      async handleSubmit() {
        await this.addCatalogEntries();

        await this.addTenants();

        await this.addUsers();

        await this.addClients();

        await this.addUnlimitedClients();

        await this.addServiceRequests();

        await this.addClientforPlanitGeoTenant();

        console.log('DONE!');
      },
      async addCatalogEntries() {
        // Get the access token from the auth wrapper
        const accessToken = await this.$auth.getTokenSilently();

        console.log('------- adding species -------');

        var speciesTest = [
          {
            uuid: 'db3fc4c5-4f22-49d8-b496-796d5befe584',
            another_uuid: 'fa5bc7dc-6d73-4a5f-9955-fd65f54727fb',
            common_name: 'Oak',
            latin_name: 'Oakulus',
            region: ['North (US)', 'Pacific Northwest (US)'],
            verified: false,
          },
          {
            uuid: '2fc5663c-5533-4ed2-afe6-578f35987fc3',
            another_uuid: 'bdcf2998-b300-4e8e-93fc-ba2303e6746c',
            common_name: 'Aspen',
            latin_name: 'Populus Tremuloides',
            region: ['North (US)'],
            verified: true,
          },
          {
            uuid: 'b5d96b82-5196-4733-8bed-d2bcc4c58b5a',
            another_uuid: '075b078f-f0f6-48c2-8e36-ebd03e47c728',
            common_name: 'Green Ash',
            latin_name: 'Fraxinus Pennsylvanica',
            region: ['Pacific Northwest (US)'],
            verified: true,
          },
          {
            uuid: '82925700-1714-4223-b1d8-b7e6bc43e9de',
            another_uuid: '00918b83-4e14-4f69-be92-ce138823c17d',
            common_name: 'Weeping Willow',
            latin_name: 'Salix Babylonica',
            region: ['Pacific Northwest (US)'],
            verified: true,
          },
          {
            uuid: '4e0dc791-fbee-482e-a89d-e891fba6455d',
            another_uuid: '1b336fc3-8581-4ae4-b25a-ca4bdf0ddb95',
            common_name: 'Ponderosa Pine',
            latin_name: 'Pinus Ponderosa',
            region: ['Pacific Northwest (US)'],
            verified: true,
          },
          {
            uuid: '01dc4da2-36cd-40c7-9e34-8daad3893937',
            another_uuid: '41d5b543-7882-425c-a0cf-0c7e5dddfc1c',
            common_name: 'Baobab',
            latin_name: 'Adansonia Digitata',
            region: ['North (US)'],
            verified: true,
          },
        ];
        var tenantSpeciesTest = speciesTest.map((x) => {
          return {
            uuid: x.another_uuid,
            species_uuid: x.uuid,
            tenant_uuid: '3a35e09f-c3d5-47f6-9a5a-7042f688ec38',
            active: true,
          };
        });
        for (var i = 0; i < speciesTest.length; i++) {
          axios({
            method: 'POST',
            url: '/service_tenants/master_species/create',
            data: speciesTest[i],
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          });
        }

        axios({
          method: 'POST',
          url: '/service_tenants/tenant_species/create_series',
          data: {
            species: tenantSpeciesTest,
          },
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
      },
      async addTenants() {
        // Get the access token from the auth wrapper
        const accessToken = await this.$auth.getTokenSilently();

        console.log('------- adding tenants -------');

        var tenants = [
          {
            uuid: 'f36deca0-d0eb-4290-aeb2-4a077c120198',
            tenant_name: 'ABC Tree Care',
            address: '4210 Selkirk Terrace',
            city: 'Snellville',
            state: 'GA',
            zip_code: '30039',
            contact_phone: '678-361-1242',
            contact_email: 'dev@planitgeo.com',
            account_status: 'Active',
            subscription_start_date: '1999-01-01 00:00:01',
            subscription_end_date: '2030-01-08 00:00:01',
            subscription_type: 'UNLIMITED',
            tenant_url: 'abctreecare',
            currency_symbol: '$',
            currency_abbr: 'USD',
            invoice_terms: 'Net 30',
            invoice_notes: 'Thank you for your business!',
            invoice_name: 'ABC Tree Care',
            invoice_address: '4210 Selkirk Terrace',
            invoice_city: 'Snellville',
            invoice_state: 'GA',
            invoice_postal_code: '30039',
          },
          {
            uuid: 'facd64c3-83d8-4fda-ae3f-843138e986b4',
            tenant_name: 'Unlimited Tree Care',
            address: '2392 Roger Oak lane',
            city: 'Snellville',
            state: 'GA',
            zip_code: '30039',
            contact_phone: '678-361-1242',
            contact_email: 'dev@planitgeo.com',
            account_status: 'Active',
            subscription_start_date: '1999-01-01 00:00:01',
            subscription_end_date: '2030-01-08 00:00:01',
            subscription_type: 'UNLIMITED',
            tenant_url: 'unlimitedtreecare',
            currency_symbol: '$',
            currency_abbr: 'USD',
            invoice_terms: 'Net 30',
            invoice_notes: 'Thank you for your business!',
            invoice_name: 'Unlimited Tree Care',
            invoice_address: '4210 Selkirk Terrace',
            invoice_city: 'Snellville',
            invoice_state: 'GA',
            invoice_postal_code: '30039',
          },
          {
            uuid: 'cffef869-38d3-4fda-a58b-e80532a196e8',
            tenant_name: 'Handy Dan the tree destroyer LLC',
            address: '2392 Roger Oak lane',
            city: 'Snellville',
            state: 'GA',
            zip_code: '30039',
            contact_phone: '678-361-1242',
            contact_email: 'dev@planitgeo.com',
            account_status: 'Active',
            subscription_start_date: '1999-01-01 00:00:01',
            subscription_end_date: '2030-01-08 00:00:01',
            subscription_type: 'UNLIMITED',
            tenant_url: 'treekillerdan',
            currency_symbol: '$',
            currency_abbr: 'USD',
            invoice_terms: 'Net 30',
            invoice_notes: 'Glad we killed your trees!',
            invoice_name: 'Handy Dan the tree destroyer LLC',
            invoice_address: '4210 Selkirk Terrace',
            invoice_city: 'Snellville',
            invoice_state: 'GA',
            invoice_postal_code: '30039',
          },
          {
            uuid: '0f51cf55-7b19-4ff5-84ba-c1da350062b0',
            tenant_name: 'Black Rock Tree Toppers, Inc',
            address: '2392 Roger Oak lane',
            city: 'Snellville',
            state: 'GA',
            zip_code: '30039',
            contact_phone: '678-361-1242',
            contact_email: 'dev@planitgeo.com',
            account_status: 'Active',
            subscription_start_date: '1999-01-01 00:00:01',
            subscription_end_date: '2030-01-08 00:00:01',
            subscription_type: 'UNLIMITED',
            tenant_url: 'blackrock',
            currency_symbol: '$',
            currency_abbr: 'USD',
            invoice_terms: 'Net 30',
            invoice_notes: 'The best tree climbers in black rock!',
            invoice_name: 'Black Rock Tree Toppers',
            invoice_address: '4210 Selkirk Terrace',
            invoice_city: 'Snellville',
            invoice_state: 'GA',
            invoice_postal_code: '30039',
          },
          {
            uuid: 'e9f92951-7902-4875-aa6a-4fe9b9fb3921',
            tenant_name: 'Hanford city tree specialists',
            address: '2392 Roger Oak lane',
            city: 'Snellville',
            state: 'GA',
            zip_code: '30039',
            contact_phone: '678-361-1242',
            contact_email: 'dev@planitgeo.com',
            account_status: 'Inactive',
            subscription_start_date: '1999-01-01 00:00:01',
            subscription_end_date: '2030-01-08 00:00:01',
            subscription_type: 'UNLIMITED',
            tenant_url: 'hanfordtreespecialists',
            currency_symbol: '$',
            currency_abbr: 'USD',
            invoice_terms: 'Net 30',
            invoice_notes: 'Special trees require special people!',
            invoice_name: 'Hanford city tree specialists',
            invoice_address: '4210 Selkirk Terrace',
            invoice_city: 'Snellville',
            invoice_state: 'GA',
            invoice_postal_code: '30039',
          },
        ];

        for (var i = 0; i < tenants.length; i++) {
          axios({
            method: 'POST',
            url: '/service_tenants/tenants/create',
            data: tenants[i],
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          });
        }
      },
      async addUsers() {
        // Get the access token from the auth wrapper
        const accessToken = await this.$auth.getTokenSilently();

        console.log('------- adding users -------');

        var users = [
          {
            uuid: '4e9d1dab-9516-464d-b1e5-230d107b71c4',
            tenant_uuid: '3a35e09f-c3d5-47f6-9a5a-7042f688ec38', //planitgeo
            role: 'admin',
            name: 'Bobby Toyota',
            email: 'btoyota@fakeemails43.com',
            password: 'ThisIsNotAVerySecureOneButItWorks*9999',
            status: 'Active',
          },
          {
            uuid: 'ae5d402a-f832-4c6e-844a-8d6282ca3420',
            tenant_uuid: '3a35e09f-c3d5-47f6-9a5a-7042f688ec38', //planitgeo
            role: 'tenant',
            name: 'Richard Ford',
            email: 'rford@fakeemails43.com',
            password: 'ThisIsNotAVerySecureOneButItWorks*9999',
            status: 'Active',
          },
          {
            uuid: '4fe2e697-afe5-4d00-ba20-848dc02eb10b',
            tenant_uuid: '3a35e09f-c3d5-47f6-9a5a-7042f688ec38', //planitgeo
            role: 'crew',
            name: 'Sarah Honda',
            email: 'shonda@fakeemails43.com',
            password: 'ThisIsNotAVerySecureOneButItWorks*9999',
            status: 'Active',
          },
          {
            uuid: 'd525f597-abd4-48d7-a8f8-446fa3c2aaf9',
            tenant_uuid: '3a35e09f-c3d5-47f6-9a5a-7042f688ec38', //planitgeo
            role: 'tenant',
            name: 'Dilbert Chevy',
            email: 'dchevy@fakeemails43.com',
            password: 'ThisIsNotAVerySecureOneButItWorks*9999',
            status: 'Inactive',
          },
          {
            uuid: '86d11022-446f-4831-a4ab-75f786aca148',
            tenant_uuid: 'facd64c3-83d8-4fda-ae3f-843138e986b4', //unlimited
            role: 'admin',
            name: 'Rodney Unlimited',
            email: 'runlimited@fakeemails43.com',
            password: 'ThisIsNotAVerySecureOneButItWorks*9999',
            status: 'Active',
          },
          {
            uuid: '5e07c4b4-fdcf-42cb-814a-fdb05bee72b8',
            tenant_uuid: 'facd64c3-83d8-4fda-ae3f-843138e986b4', //unlimited
            role: 'tenant',
            name: 'Sam Unlimited',
            email: 'sunlimited@fakeemails43.com',
            password: 'ThisIsNotAVerySecureOneButItWorks*9999',
            status: 'Active',
          },
          {
            uuid: '3a997f08-d51c-47d4-86d0-9bb26d3cd1d4',
            tenant_uuid: 'facd64c3-83d8-4fda-ae3f-843138e986b4', //unlimited
            role: 'tenant',
            name: 'Bob Unlimited',
            email: 'bunlimited@fakeemails43.com',
            password: 'ThisIsNotAVerySecureOneButItWorks*9999',
            status: 'Active',
          },
          {
            uuid: '75495c89-1f20-40c7-a6be-41eec15d5e4d',
            tenant_uuid: 'facd64c3-83d8-4fda-ae3f-843138e986b4', //unlimited
            role: 'tenant',
            name: 'Danny Unlimited',
            email: 'dunlimited@fakeemails43.com',
            password: 'ThisIsNotAVerySecureOneButItWorks*9999',
            status: 'Inactive',
          },
        ];

        for (var i = 0; i < users.length; i++) {
          axios({
            method: 'POST',
            url: '/service_users/users/create',
            data: users[i],
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          });
        }
      },
      async addClients() {
        // Get the access token from the auth wrapper
        const accessToken = await this.$auth.getTokenSilently();

        console.log('------- adding clients -------');

        var planitGeoClients = [
          {
            uuid: 'd9eff757-4ac7-4d58-a01e-c485c9bbfe7e',
            number: 0,
            client_name: 'Jimmy Fallow - PG',
            contact_name: 'Roy Elbow',
            contact_phone: '333-222-1234',
            contact_email: 'roy@elbows.com',
            address: '123 elbow ave',
            city: 'asuncion',
            state: 'LN',
            zip_code: 12345,
            country: 'Paraguay',
            client_type: 'Business',
            client_status: 'Active',
            tenant_uuid: '3a35e09f-c3d5-47f6-9a5a-7042f688ec38', //planitgeo
          },
          {
            uuid: '218657d8-b794-4d40-90e8-1cc553cb77e4',
            number: 0,
            client_name: 'Richard Corriander - PG',
            contact_name: 'Richard Corriander',
            contact_phone: '333-222-1234',
            contact_email: 'roy@elbows.com',
            address: '123 elbow ave',
            city: 'asuncion',
            state: 'LN',
            zip_code: 12345,
            country: 'Paraguay',
            client_type: 'Business',
            client_status: 'Active',
            tenant_uuid: '3a35e09f-c3d5-47f6-9a5a-7042f688ec38', //planitgeo
          },
          {
            uuid: 'e1eb42c0-d00e-4a9d-9c98-c6fc0b94478b',
            number: 0,
            client_name: 'Sammy Nutmeg - PG',
            contact_name: 'Sammy Nutmeg',
            contact_phone: '333-222-1234',
            contact_email: 'roy@elbows.com',
            address: '123 elbow ave',
            city: 'asuncion',
            state: 'LN',
            zip_code: 12345,
            country: 'Paraguay',
            client_type: 'Business',
            client_status: 'Active',
            tenant_uuid: '3a35e09f-c3d5-47f6-9a5a-7042f688ec38', //planitgeo
          },
          {
            uuid: '2b890b71-45f3-44e8-b10a-e8708f76b695',
            number: 0,
            client_name: 'Leslie Oregano - PG',
            contact_name: 'Leslie Oregano',
            contact_phone: '333-222-1234',
            contact_email: 'roy@elbows.com',
            address: '123 elbow ave',
            city: 'asuncion',
            state: 'LN',
            zip_code: 12345,
            country: 'Paraguay',
            client_type: 'Business',
            client_status: 'Active',
            tenant_uuid: '3a35e09f-c3d5-47f6-9a5a-7042f688ec38', //planitgeo
          },
        ];

        for (var i = 0; i < planitGeoClients.length; i++) {
          await axios({
            method: 'POST',
            url: '/service_clients/clients/create',
            data: planitGeoClients[i],
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          });
        }
      },
      async addUnlimitedClients() {
        // Get the access token from the auth wrapper
        const accessToken = await this.$auth.getTokenSilently();

        console.log('------- adding unlimited clients -------');

        var unlimitedClients = [
          {
            uuid: '76bdfe71-bcef-40cc-aa70-b6dbb167159e',
            number: 0,
            client_name: 'Jimmy Fallow - Unlimited',
            contact_name: 'Roy Elbow',
            contact_phone: '333-222-1234',
            contact_email: 'roy@elbows.com',
            address: '123 elbow ave',
            city: 'asuncion',
            state: 'LN',
            zip_code: 12345,
            country: 'Paraguay',
            client_type: 'Business',
            client_status: 'Active',
            tenant_uuid: 'facd64c3-83d8-4fda-ae3f-843138e986b4', //unlimited
          },
          {
            uuid: '4fb859f6-4837-457e-bff8-2f981ddfd0f6',
            number: 0,
            client_name: 'Richard Corriander - Unlimited',
            contact_name: 'Richard Corriander',
            contact_phone: '333-222-1234',
            contact_email: 'roy@elbows.com',
            address: '123 elbow ave',
            city: 'asuncion',
            state: 'LN',
            zip_code: 12345,
            country: 'Paraguay',
            client_type: 'Business',
            client_status: 'Active',
            tenant_uuid: 'facd64c3-83d8-4fda-ae3f-843138e986b4', //unlimited
          },
          {
            uuid: 'c76bc06b-28bc-4f56-a31c-263ec7570e2d',
            number: 0,
            client_name: 'Sammy Nutmeg - Unlimited',
            contact_name: 'Sammy Nutmeg',
            contact_phone: '333-222-1234',
            contact_email: 'roy@elbows.com',
            address: '123 elbow ave',
            city: 'asuncion',
            state: 'LN',
            zip_code: 12345,
            country: 'Paraguay',
            client_type: 'Business',
            client_status: 'Active',
            tenant_uuid: 'facd64c3-83d8-4fda-ae3f-843138e986b4', //unlimited
          },
          {
            uuid: 'a3063435-a865-47fb-b889-37e233ce6a6d',
            number: 0,
            client_name: 'Leslie Oregano - Unlimited',
            contact_name: 'Leslie Oregano',
            contact_phone: '333-222-1234',
            contact_email: 'roy@elbows.com',
            address: '123 elbow ave',
            city: 'asuncion',
            state: 'LN',
            zip_code: 12345,
            country: 'Paraguay',
            client_type: 'Business',
            client_status: 'Active',
            tenant_uuid: 'facd64c3-83d8-4fda-ae3f-843138e986b4', //unlimited
          },
        ];

        for (var i = 0; i < unlimitedClients.length; i++) {
          axios({
            method: 'POST',
            url: '/service_clients/clients/create',
            data: unlimitedClients[i],
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          });
        }
      },
      async addServiceRequests() {
        // Get the access token from the auth wrapper
        const accessToken = await this.$auth.getTokenSilently();

        console.log(
          '------- adding service requests for unlimited tenant -------'
        );

        var uuidOne = '094f6391-ca65-49ca-af53-04cc5c993578';
        // CREATE NEW SERVICE REQUEST FOR UNLIMITED TENANT
        await axios({
          method: 'POST',
          url: '/service_jobs/service_requests/create',
          data: {
            uuid: uuidOne,
            number: 0,
            client_name: 'The Wowzer Institute',
            requestor_name: 'Lillian',
            requestor_phone: '222-333-8888',
            requestor_email: 'lillian@sunset.com',
            address: '2 E 91st St',
            client_type: 'Business',
            city: 'New York',
            state: 'NY',
            zip_code: '10128',
            country: 'USA',
            special_instructions: 'Please pet the dog',
            date_submitted: new Date('2018-12-17T03:24:00'),
            service_request_status: 'Submitted',
            tenant_uuid: 'facd64c3-83d8-4fda-ae3f-843138e986b4',
          },
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }).then(async () => {
          await axios({
            method: 'PUT',
            url: '/service_jobs/service_requests/approve/' + uuidOne,
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          });
        });

        var uuidTwo = 'bba53df9-1423-4f96-a230-d38c38654d2a';
        // CREATE NEW SERVICE REQUEST FOR UNLIMITED TENANT
        await axios({
          method: 'POST',
          url: '/service_jobs/service_requests/create',
          data: {
            uuid: uuidTwo,
            number: 0,
            client_name: 'The Wowzer Institute',
            requestor_name: 'Lillian',
            requestor_phone: '222-333-8888',
            requestor_email: 'lillian@sunset.com',
            address: '2 E 91st St',
            client_type: 'Business',
            city: 'New York',
            state: 'NY',
            zip_code: '10128',
            country: 'USA',
            special_instructions: 'Please pet the dog',
            date_submitted: new Date('2018-12-17T03:24:00'),
            service_request_status: 'Submitted',
            tenant_uuid: 'facd64c3-83d8-4fda-ae3f-843138e986b4',
          },
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }).then(async () => {
          await axios({
            method: 'PUT',
            url: '/service_jobs/service_requests/approve/' + uuidTwo,
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          });
        });
      },
      async addClientforPlanitGeoTenant() {
        // Get the access token from the auth wrapper
        const accessToken = await this.$auth.getTokenSilently();

        console.log('------- creating new client for planitgeo tenant -------');
        // CREATE NEW CLIENT FOR PLANITGEO TENANT
        axios({
          method: 'POST',
          url: '/service_clients/clients/create',
          data: {
            uuid: '15f40fd8-2a72-4197-a966-908bfc13501e',
            number: 0,
            client_name: 'Aloha by the Sea',
            contact_name: 'Roy Elbow',
            contact_phone: '333-222-1234',
            contact_email: 'roy@elbows.com',
            address: '123 elbow ave',
            city: 'asuncion',
            state: 'LN',
            zip_code: 12345,
            country: 'Paraguay',
            client_type: 'Business',
            client_status: 'Active',
            tenant_uuid: '3a35e09f-c3d5-47f6-9a5a-7042f688ec38',
          },
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }).then(() => {
          // CREATE NEW SERVICE REQUEST
          axios({
            method: 'POST',
            url: '/service_jobs/service_requests/create',
            data: {
              uuid: '92455a7e-c4af-40c2-aeb3-b03abac35745',
              number: 0,
              client_name: 'Sunset Estates',
              requestor_name: 'Lillian',
              requestor_phone: '222-333-8888',
              requestor_email: 'lillian@sunset.com',
              address: '1000 5th Ave',
              client_type: 'Business',
              city: 'New York',
              state: 'NY',
              zip_code: '10028',
              country: 'USA',
              special_instructions: 'Please pet the dog',
              date_submitted: new Date('2018-12-17T03:24:00'),
              service_request_status: 'Submitted',
              tenant_uuid: '3a35e09f-c3d5-47f6-9a5a-7042f688ec38',
            },
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }).then(() => {
            // APPROVE SR TO CREATE ESTIMATE
            axios({
              method: 'PUT',
              url: '/service_jobs/service_requests/approve/92455a7e-c4af-40c2-aeb3-b03abac35745',
            });
          });
        });
      },
    },
  };
</script>

<style scoped>
  .header {
  }
</style>
